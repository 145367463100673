import styled from 'styled-components';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import items from '../Navbar/items.json';
import Util from '../../utils';
import info from '../campusInfo.json';
import Contacts from './Contacts';
import Directory from './Directory';
import StayConnected from './StayConnected';
import SocialIcons from './SocialIcons';

const Section = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${$.color.blue3};
  color: ${$.color.white};
  padding-top: ${$.layout().padding2}px;
  padding-bottom: ${$.layout().padding2}px;
  font-size: 14px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding-top: ${$.layout().padding1}px;
    padding-bottom: ${$.layout().padding1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 15px;
  }
  // #endregion
`;

const Divider = styled.div`
  background: ${$.color.white};
  opacity: 0.3;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: 1px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100%;
    height: 1px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(1200px - ${$.layout().padding5 * 2}px);
    display: grid;
    grid-template-columns: auto 1px auto 1px auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'footerContacts divider1 footerDirectory divider2 footerConnected'
      'footerContacts divider1 footerDirectory divider2 footerConnected';
    grid-column-gap: ${$.layout().margin3}px;
    grid-row-gap: ${$.layout().margin3}px;

    & > ${Divider}.footer-divider-1 {
      grid-area: divider1;
    }

    & > ${Divider}.footer-divider-2 {
      grid-area: divider2;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'footerContacts footerDirectory'
      'footerConnected footerConnected';
    grid-column-gap: ${$.layout().margin3}px;
    grid-row-gap: ${$.layout().margin3}px;

    & .footer-divider-1,
    & .footer-divider-2 {
      display: none;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
  // #endregion
`;

const CopyrightContainer = styled.div`
  font-family: Lato Regular;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-top: ${$.layout().margin2}px;
    width: 1200px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-top: ${$.layout().margin3}px;
    padding: 0 ${$.layout().padding2}px;
    width: calc(100% - ${$.layout().padding2}px * 2);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
  }
  // #endregion
`;

const Copyright = styled.div``;

const campuses = Util.addKeys(info.campuses).map(({ address, ...item }) => ({
  ...item,
  address: Util.addKeys(address.map((add) => ({ value: add }))),
}));

const phoneLineHours = Util.addKeys(
  info.phoneLineHours.map((item) => ({ value: item }))
);

const peak = Util.addKeys(info.peak.map((item) => ({ value: item })));

const footerItems = {};

items.forEach(({ footerItem, dropdownLink, dropdownText, subLinks = [] }) => {
  footerItems[footerItem] = footerItems[footerItem] || {
    link: dropdownLink || null,
    text: dropdownText,
    subItems: [],
  };

  subLinks.forEach(({ footerSubText, subLink }) => {
    footerItems[footerItem].subItems.push({
      key: shortid.generate(),
      link: subLink,
      text: footerSubText,
    });
  });
});

const Footer = ({ className }) => (
  <Section className={className} id="footer">
    <Container>
      <Contacts
        campuses={campuses}
        phoneLineHours={phoneLineHours}
        peak={peak}
        phone={info.phone}
      />
      <Divider className="footer-divider-1" />
      <Directory footerItems={footerItems} />
      <Divider className="footer-divider-2" />
      <StayConnected />
    </Container>
    <CopyrightContainer>
      <Copyright>
        &copy;
        {` Copyright 2016 - ${new Date().getFullYear()} MY Code Campus Sdn Bhd All rights reserved.`}
      </Copyright>
    </CopyrightContainer>
  </Section>
);

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
