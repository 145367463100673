import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import CardButton from './CardButton';

const StyledCardButton = styled(CardButton)``;

const Container = styled(({ link, children, className }) => {
  switch (link[0]) {
    // Internal links
    case '/':
      return (
        <Link to={link} className={className}>
          {children}
        </Link>
      );
    // External links
    case 'h':
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
        >
          {children}
        </a>
      );
    // Mailto links
    case 'm':
      return (
        <a href={link} className={className}>
          {children}
        </a>
      );
    default:
      return (
        <Link to={link} className={className}>
          {children}
        </Link>
      );
  }
})`
  text-decoration: none;
  border-radius: 32px;
  overflow: hidden;
  box-shadow: 0px 1px 10px #dfdfdf;
  text-decoration: none;
  transform: translateY(0);
  transition: transform 0.3s ease;
  background-color: ${$.color.white};

  &:hover {
    transform: translateY(-0.3em);

    & ${StyledCardButton} {
      letter-spacing: 0.3px;
      border: 1px solid ${$.proTraining.blue};
      color: ${$.proTraining.blue};
      svg {
        right: -10px;
        fill: ${$.proTraining.blue};
      }

      & span {
        transform: translateY(0) scale(2);
      }
    }
  }
`;

const Info = styled.div`
  padding: 20px 25px 25px 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px - 40px);
`;

const Title = styled.h3`
  font-family: Lato Bold;
  font-size: 19px;
  line-height: 22px;
  color: ${$.proTraining.bluegray};
  margin: 0 0 15px 0;
`;

const Content = styled.div`
  font-family: Lato Light;
  color: ${$.color.black};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;
  flex-grow: 1;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    width: 80%;
    // height: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d4d4d4;
  margin: 10px 0 20px 0;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;

  & > span {
    font-size: 15px;
    &:first-child {
      width: 25%;
      text-transform: uppercase;
      font-family: Lato Bold;
      margin-right: 20px;
      color: ${$.color.gray3};
      align-self: flex-start;
    }
    &:last-child {
      width: 75%;
      font-family: Lato Light;
      color: ${$.color.black2};

      small {
        font-size: 12.5px;
      }
    }
  }
`;

const Card = ({
  buttonMessage,
  link,
  image,
  title,
  desc,
  level,
  venue,
  fee,
}) => (
  <Container link={link}>
    <ImageContainer>{image}</ImageContainer>
    <Info>
      <Title>{title}</Title>
      <InfoRow>
        <span>Level</span>
        <span>{level}</span>
      </InfoRow>
      {/* <InfoRow>
        <span>Venue</span>
        <span>{venue}</span>
      </InfoRow>
      <InfoRow>
        <span>Fee</span>
        <span dangerouslySetInnerHTML={{ __html: fee }} />
      </InfoRow> */}
      <Divider />
      <Content>{desc}</Content>
      <StyledCardButton text={buttonMessage} />
    </Info>
  </Container>
);

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.element,
  desc: PropTypes.string,
  buttonMessage: PropTypes.string,
  link: PropTypes.string,
  level: PropTypes.string,
  venue: PropTypes.string,
  fee: PropTypes.string,
};

Card.defaultProps = {
  title: '',
  level: 'Basic',
  image: <></>,
  desc: '',
  buttonMessage: 'Learn More',
  link: '',
  venue: 'Online',
  fee: '',
};

export default Card;
