import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import {
  Description1,
  Description2,
  Description3,
  Description4,
} from './descriptions';
import WhyUs1Img from '../../../assets/images/landing/why-us/1.jpg';
import WhyUs4Img from '../../../assets/images/landing/why-us/4.jpg';
import WhyUs5Img from '../../../assets/images/landing/why-us/5.jpg';
import WaveBackground from '../../../assets/icons/badge-waves-white.svg';

gsap.registerPlugin(ScrollTrigger);

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.h2`
  color: ${$.proTraining.bluegray};
  font-family: Lato Bold;
  font-size: ${$.fontSizes.xlarge};
  position: sticky;
  text-align: center;
  top: 110px;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 24px;
  }
  // #endregion
`;

const Content = styled.div`
  & > * {
    position: sticky;
    &:nth-child(1) {
      top: 230px;
      height: calc(530px - ${$.layout().padding4 * 2}px);
    }
    &:nth-child(2) {
      top: 285px;
    }
    &:nth-child(3) {
      top: 335px;
    }
    &:nth-child(4) {
      top: 385px;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    & > * {
      &:nth-child(1) {
        height: auto;
      }
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > * {
      &:nth-child(1) {
        height: auto;
        top: 220px;
      }
      &:nth-child(2) {
        top: 275px;
      }
      &:nth-child(3) {
        top: 330px;
      }
      &:nth-child(4) {
        top: 385px;
      }
    }
  }
  // #endregion
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: ${$.layout().padding4}px;
  height: calc(500px - ${$.layout().padding4 * 2}px);
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: ${$.color.white};
  margin-bottom: ${$.layout().margin4}px;
  will-change: transform;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    height: auto;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: auto;
  }
  // #endregion
`;

const CardTopTitle = styled.div`
  font-family: Lato Bold;
  font-size: 22px;
  position: absolute;
  top: 0;
  left: 0;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const AlphabetBox = styled.div`
  ${({ color }) => `
  background-color: ${color};
  border-radius: 20px;
  font-size: 50px;
  font-family: Lato Bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  min-width: 50px;
  text-align: center;
  margin-bottom: ${$.layout().margin4}px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
    opacity: 0.4;
  }

  & > span {
    color: ${$.color.white};
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`}
`;

const Info = styled.div`
  width: 30%;
  height: calc(100% - 160px);
  padding: 80px 10%;
  position: relative;

  p {
    line-height: 1.3em;
    font-size: 16px;
  }

  a {
    font-family: Lato Bold;
    font-size: 15.5px;
    text-decoration: none;
    color: ${$.color.orange4};
    &:visited {
      color: ${$.color.orange4};
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 80%;
    padding: 80px 10% 50px 10%;
    height: auto;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 88%;
    height: auto;
    padding: 50px 8% 30px 6%;
  }
  // #endregion
`;

const CardTitle = styled.div`
  ${({ color }) => `
  margin-bottom: ${$.layout().margin4}px;
  font-family: Lato Bold;
  font-size: 30px;
  color: ${color};

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 24px;
  }
  // #endregion
`}
`;

const ImgContainer = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 10px 10px 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
    border-radius: 0 0 10px 10px;
    height: 350px;
    flex: initial;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    border-radius: 0 0 10px 10px;
    height: 350px;
    flex: initial;
  }
  // #endregion
`;

const items = Utils.addKeys([
  {
    title: 'For Everyone',
    Desc: Description2,
    img: WhyUs5Img,
    scale: 0.96,
    alphabets: 'F',
    color: $.color.blue3,
  },
  {
    title: 'Join Us',
    Desc: Description1,
    img: WhyUs1Img,
    scale: 0.94,
    alphabets: 'J',
    color: $.color.red,
  },
  {
    title: 'Contact Us',
    Desc: Description4,
    img: WhyUs4Img,
    scale: 1,
    alphabets: 'C',
    color: $.color.black2,
  },
]);

const CodingForEveryone = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const { childNodes } = containerRef.current;
    const t1 = gsap.timeline();

    childNodes.forEach((node, index) => {
      t1.to(node, {
        scaleX: items[index].scale,
        scrollTrigger: {
          trigger: node,
          start: '-=100px center',
          end: 'bottom center',
          scrub: 1,
        },
      });
    });
  }, []);

  return (
    <Section>
      <Container>
        <Title>Tech Training in Malaysia</Title>
        <Content ref={containerRef}>
          {items.map(({ title, key, Desc, alphabets, color, img }) => (
            <Card key={key}>
              <Info>
                <CardTopTitle>{title}</CardTopTitle>
                <AlphabetBox color={color}>
                  <WaveBackground />
                  <span>{alphabets || ''}</span>
                </AlphabetBox>
                <CardTitle color={color}>{title}</CardTitle>
                <Desc />
              </Info>
              <ImgContainer>
                <img src={img} alt="Why Choose SG Code Campus" />
              </ImgContainer>
            </Card>
          ))}
        </Content>
      </Container>
    </Section>
  );
};

export default CodingForEveryone;
