import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import AWSPartnerLogo from '../../../assets/images/landing/partners/aws_partner_network.svg';
import GoogleCloudLogo from '../../../assets/images/landing/partners/google-cloud.svg';
import $ from '../../../styles/global';
import Boxes from './Boxes';

const Section = styled.section`
  background-color: #f7f7f7;
  margin-bottom: 60px !important;
  padding: 20px 0;

  & > div {
    width: 1100px !important;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    & > div {
      width: calc(100% - 64px) !important;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > div {
      width: calc(100% - 64px) !important;
    }
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    margin-bottom: 30px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-top: 30px;
    margin-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  // #endregion
`;

const Info = styled.div`
  width: 40%;
  margin-top: 30px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: 40%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  width: 60%;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: 60%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Lato Bold;
  font-size: 35px;
  line-height: 43px;
  color: ${$.proTraining.blue};
  letter-spacing: -0.5px;
  padding-bottom: 15px;

  & > span {
    color: ${$.proTraining.orange1};
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 32px;
    line-height: 41px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 32px;
    line-height: 41px;
  }
  // #endregion
`;

const Divider = styled.div`
  background-color: ${$.proTraining.orange2};
  box-shadow: 0px 1px 5px ${$.proTraining.orange2};
  height: 4px;
  width: 46px;
  border-radius: 5px;
  margin-left: 20px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-left: initial;
    margin: auto;
    width: 55px;
    margin-bottom: 30px;
  }
  // #endregion
`;

const Content = styled.p`
  font-family: Lato Light;
  font-size: 15px;
  line-height: 18px;
  color: ${$.color.black};
  margin-bottom: 30px;
`;

const OfficialTrainingPartnerOf = styled.div`
  font-family: Lato Bold;
  font-size: 15px;
  margin-bottom: 40px;

  & > span {
    display: block;
    margin-bottom: 20px;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${$.layout().margin3}px;

    & .apple {
      width: 130px;
    }

    & .aws {
      width: 100px;
    }

    & .google {
      width: 120px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 30px;
    & > div {
      justify-content: center;
    }
  }
  // #endregion
`;

const Banner = () => (
  <Section>
    <Container>
      <Info>
        <Title>
          <span>Get Certified with AWS:</span> Cloud, AI and Data Science
          Mastery
        </Title>
        <Divider />
        <Content>
          Discover your competitive edge with our comprehensive tech training.
        </Content>
        <OfficialTrainingPartnerOf>
          <span>Official Training Partner for:</span>
          <div>
            {/* <StaticImage
              src="../../../assets/images/landing/partners/apple_consultants_network.png"
              className="apple"
              alt="apple consultants network logo"
            /> */}
            <AWSPartnerLogo className="aws" />
            {/* <GoogleCloudLogo className="google" /> */}
          </div>
        </OfficialTrainingPartnerOf>
        <Boxes />
      </Info>
      <ImageContainer>
        <StaticImage
          src="../../../assets/images/professional-training/professional-training-banner-2.png"
          alt="banner image"
        />
      </ImageContainer>
    </Container>
    {/* <Cards /> */}
  </Section>
);

export default Banner;
