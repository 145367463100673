import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/global';
import { Context } from './context';
import { Subtitle, Body, Label, OutboundLink } from './common';

const Container = styled.div`
  grid-area: courseCard;
  padding: ${({ theme }) => theme.layout().padding4}px;
  box-shadow: ${({ theme }) => theme.dropShadow.repressed};
  border-radius: ${({ theme }) => theme.border().radius2}px;
  overflow: hidden;
  position: relative;

  & ${Subtitle} {
    padding-top: ${({ theme }) => theme.layout().padding4}px;
    margin-bottom: ${({ theme }) => theme.layout().margin5}px;
  }

  & ${Label} {
    margin-bottom: ${({ theme }) => theme.layout().margin5}px;
  }

  & ${Body} {
    margin-bottom: ${({ theme }) => theme.layout().margin4}px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 200px;
  width: calc(100% + ${({ theme }) => theme.layout().padding4 * 2}px);
  top: -${({ theme }) => theme.layout().padding4}px;
  left: -${({ theme }) => theme.layout().padding4}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;

  // & img {
  //   position: absolute;
  //   width: 100%;
  // }
`;

const CourseLevelBanner = styled.div`
  // Variables
  --width: 50px;
  --backgroundColor: ${({ theme }) => theme.color.orange3};

  position: absolute;
  z-index: 2;
  top: -${({ theme }) => theme.layout().padding2}px;
  right: ${({ theme }) => theme.layout().padding4}px;
  background-color: var(--backgroundColor);
  width: var(--width);
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  user-select: none;
  transition: transform 0.3s;

  // Flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Font
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-family: ${({ theme }) => theme.fontFamily.body.semiBold};
  color: ${({ theme }) => theme.color.white};
  writing-mode: vertical-rl;

  // Spacing
  padding: ${({ theme }) => theme.layout().padding1}px 0
    ${({ theme }) => theme.layout().padding3}px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -13px;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-left: calc(var(--width) / 2) solid var(--backgroundColor);
    border-right: calc(var(--width) / 2) solid var(--backgroundColor);
  }

  // Hover
  &:hover {
    transform: translateY(${({ theme }) => theme.layout().padding3}px);
  }
`;

const DetailsContainer = styled.div``;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: flex-start
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().padding4}px;
  margin-top: ${({ theme }) => theme.layout().margin3}px;
`;

const CourseCard = ({
  title,
  coursePartnerLink,
  image,
  upcomingModule,
  date,
  day,
  modulesInformation,
  courseLevel,
  feesInformation,
  coursesPage,
}) => {
  return (
    <Container id="course-information">
      <CourseLevelBanner>
        {courseLevel}
        <span />
      </CourseLevelBanner>
      <ImageContainer image={image} />
      <DetailsContainer>
        <Subtitle>{title}</Subtitle>
        {/* <Label>Upcoming Module:</Label>
        <Body>{upcomingModule}</Body>
        <Label>Next Start Date:</Label>
        <Body>
          {date} ({day})
        </Body> */}
        <Label>Modules Information:</Label>
        <Body
          dangerouslySetInnerHTML={{
            __html: modulesInformation,
          }}
        ></Body>
        {/* <Label>Fees:</Label>
        <Body dangerouslySetInnerHTML={{ __html: feesInformation }} /> */}
      </DetailsContainer>
      {!coursesPage && (
        <LinksContainer>
          <OutboundLink
            color={$.color.blue3}
            background={$.color.white}
            href={coursePartnerLink}
          >
            Learn More
          </OutboundLink>
          <OutboundLink
            color={$.color.blue3}
            background={$.color.white}
            href={coursePartnerLink}
          >
            Contact Us
          </OutboundLink>
        </LinksContainer>
      )}
    </Container>
  );
};

CourseCard.defaultProps = {
  coursesPage: false,
};

CourseCard.propTypes = {
  title: PropTypes.string.isRequired,
  coursePartnerLink: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  upcomingModule: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  modulesInformation: PropTypes.string.isRequired,
  courseLevel: PropTypes.string.isRequired,
  feesInformation: PropTypes.string.isRequired,
  coursesPage: PropTypes.bool,
};

export default CourseCard;
