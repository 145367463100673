import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import RightChevron from '../../assets/icons/right-chevron.svg';

const Container = styled.div`
  margin-bottom: 30px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 0;
  }
  // #endregion
`;

const TabContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 6px;

    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > div {
      justify-content: flex-start;
    }
  }
  // #endregion
`;

const Tab = styled.div`
  background-color: ${$.color.white};
  user-select: none;
  box-shadow: 0px 1px 10px #dfdfdf;
  border-radius: 25px;
  color: ${$.color.black2};
  font-family: Lato Regular;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  white-space: no-wrap;
  min-width: 100px;
  padding: 15px 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform: translateY(0);
  transition: 0.2s;
  display: inline-block;

  &:hover {
    cursor: pointer;
    transform: translateY(-0.2em);
  }

  &.hover,
  &.selected {
    & > span {
      width: 100%;
    }
    color: ${$.color.white};
  }

  & > span {
    transition: 0.35s;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #055399;
    position: absolute;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    min-width: 130px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    min-width: 120px;
  }
  // #endregion
`;

const ScrollToView = styled.div`
  display: none;
  padding: 10px 0;
  margin-bottom: 30px;

  svg {
    width: 14px;
    height: 14px;
    fill: ${$.color.black};
  }

  div {
    width: 60px;
    height: 3px;
    position: relative;
    right: -8px;
    background-color: ${$.color.black};
  }

  span {
    font-size: 13.5px;
    font-family: Lato Light;
    line-height: 16px;
    position: relative;
    top: -1px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  // #endregion
`;

const Tabs = ({ items, setSelected, selected }) => (
  <Container>
    <TabContainer>
      <div>
        {items.map(({ value, tab }) => (
          <Tab
            className={selected === value ? 'selected' : ''}
            onClick={() => {
              setSelected(value);
            }}
            key={`tag_${value}`}
          >
            {tab}
            <span />
          </Tab>
        ))}
      </div>
    </TabContainer>
    <ScrollToView>
      <span>Scroll to view more</span>
      <div />
      <RightChevron />
    </ScrollToView>
  </Container>
);

Tabs.defaultProps = {
  items: [],
  selected: 'javascript',
  setSelected: () => {},
};

Tabs.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      tab: PropTypes.string,
    })
  ),
};

export default Tabs;
