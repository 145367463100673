import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';

const Section = styled.section`
  margin-bottom: 70px !important;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: ${$.fontSizes.xlarge};
  line-height: 1.2em;
  color: ${$.proTraining.bluegray};
  margin-bottom: ${$.layout().margin4}px;
  text-align: center;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: Lato Regular;
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  margin-top: ${$.layout().margin2}px;

  & > div {
    margin: auto;
  }

  ${() => {
    const modifier = 0.8;

    return `
    & .lli {
      width: ${120 * modifier}px;
    }
    & .getgo {
      width: ${80 * modifier}px;
    }
    & .cpf {
      width: ${60 * modifier}px;
    }
    & .dsta {
      width: ${80 * modifier}px;
    }
    & .infineon {
      width: ${100 * modifier}px;
    }
    & .nusrisk {
      width: ${100 * modifier}px;
    }
    & .changi {
      width: ${100 * modifier}px;
    }
    & .ocbc {
      width: ${100 * modifier}px;
    }
    & .nuslaw {
      width: ${100 * modifier}px;
    }
    & .nuslaw {
      width: ${100 * modifier}px;
    }
    & .shopee {
      width: ${100 * modifier}px;
    }
    & .blackrock {
      width: ${100 * modifier}px;
    }
    & .paypal {
      width: ${100 * modifier}px;
    }

    & .apu {
      width: ${120 * modifier}px;
    }
    & .help {
      width: ${100 * modifier}px;
    }
    & .mdec {
      width: ${100 * modifier}px;
    }
    & .msu {
      width: ${100 * modifier}px;
    }
    & .tarumt {
      width: ${100 * modifier}px;
    }
    & .uitm-selangor {
      width: ${120 * modifier}px;
    }
    & .ump {
      width: ${100 * modifier}px;
    }
    & .uthm {
      width: ${100 * modifier}px;
    }
    & .utm {
      width: ${100 * modifier}px;
    }
    `;
  }}

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 0px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      /* &:nth-child(8) {
        grid-column: initial;
      }
      &:nth-child(10) {
        grid-column: 2 / 3;
      } */
    }

    ${() => {
      const modifier = 0.6;
      return `
      
      & .lli {
        width: ${120 * modifier}px;
      }
      & .getgo {
        width: ${80 * modifier}px;
      }
      & .cpf {
        width: ${60 * modifier}px;
      }
      & .dsta {
        width: ${80 * modifier}px;
      }
      & .infineon {
        width: ${100 * modifier}px;
      }
      & .nusrisk {
        width: ${100 * modifier}px;
      }
      & .changi {
        width: ${100 * modifier}px;
      }
      & .ocbc {
        width: ${100 * modifier}px;
      }
      & .nuslaw {
        width: ${100 * modifier}px;
      }
      & .nuslaw {
        width: ${100 * modifier}px;
      }
      & .shopee {
        width: ${100 * modifier}px;
      }
      & .blackrock {
        width: ${100 * modifier}px;
      }
      & .paypal {
        width: ${100 * modifier}px;
      }

      & .apu {
        width: ${120 * modifier}px;
      }
      & .help {
        width: ${100 * modifier}px;
      }
      & .mdec {
        width: ${100 * modifier}px;
      }
      & .msu {
        width: ${100 * modifier}px;
      }
      & .tarumt {
        width: ${100 * modifier}px;
      }
      & .uitm-selangor {
        width: ${120 * modifier}px;
      }
      & .ump {
        width: ${100 * modifier}px;
      }
      & .uthm {
        width: ${100 * modifier}px;
      }
      & .utm {
        width: ${100 * modifier}px;
      }

      `;
    }}
  }
  // #endregion
`;

const ClientsCollaborations = () => (
  <Section>
    <Container>
      <Title>Our Learner Institutions</Title>
      <Subtitle>
        Educating Malaysian students in collaboration with MDEC and AWS
      </Subtitle>
      <Logos>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/apu.png"
            className="apu"
            alt="APU Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/help_university.png"
            className="help"
            alt="HELP University Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/msu.png"
            className="msu"
            alt="MSU Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/tarumt.png"
            className="tarumt"
            alt="TARUMT Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/uitm_selangor.png"
            className="uitm-selangor"
            alt="UiTM Cawangan Selangor Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/university_malaysia_pahang.png"
            className="ump"
            alt="University Malaysia Pahang Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/uthm.png"
            className="uthm"
            alt="UTHM Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/utm.png"
            className="utm"
            alt="UTM Logo"
          />
        </div>
      </Logos>
    </Container>
  </Section>
);

export default ClientsCollaborations;
