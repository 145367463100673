import { Link } from 'gatsby';

const Description1 = () => (
  <p>
    Coding and tech competencies have become essential skills in today&apos;s
    digital world. If you want to stay ahead of the curve, consider joining our
    coding and tech courses at MY Code Campus in Malaysia.
  </p>
);

const Description2 = () => (
  <p>
    At our campus, you can enrol in courses led by a team of highly qualified
    instructors. We offer cloud, coding, and data science courses in Malaysia,
    designed to help professionals enhance their skills and earn globally
    recognised tech certifications. Whether you’re a beginner or seeking
    advanced training, we have the right course to support your career growth.
  </p>
);

const Description3 = () => (
  <p>
    From <Link to="/youth-programmes/basics/">coding classes for kids</Link> to
    advanced diploma courses for <Link to="/partners/smu/">professionals</Link>{' '}
    in Python Programming and Applied Data Analytics, you can learn everything
    about computer programming and how to code.
  </p>
);

const Description4 = () => (
  <p>
    If you are unsure of{' '}
    <Link to="/blog/how-kids-teens-start-coding">
      how to start your coding journey
    </Link>
    , we can also{' '}
    <Link to="/coding-course-recommender/">recommend a course</Link> for you.{' '}
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true"
      target="_blank"
      rel="noopener noreferrer"
    >
      Email us
    </a>{' '}
    so that we can guide you further.
  </p>
);

export { Description1, Description2, Description3, Description4 };
