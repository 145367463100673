import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import BerkeleyLogo from '../../assets/images/landing/berkeley.svg';
import $ from '../../styles/global';
import StandardCharteredLogo from '../../assets/images/landing/standard-chartered.svg';
import SeaLogo from '../../assets/images/landing/sea_ltd.svg';
import STEngineeringLogo from '../../assets/images/landing/st-engineering.svg';

const Section = styled.section`
  margin-bottom: 70px !important;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: ${$.fontSizes.xlarge};
  line-height: 1.2em;
  color: ${$.proTraining.bluegray};
  margin-bottom: 1em;
  text-align: center;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 30px;

  & > div {
    margin: auto;
  }

  ${() => {
    const modifier = 0.8;

    return `
    & .astar {
      width: ${120 * modifier}px;
    }

    & .barclays {
      width: ${120 * modifier}px;
    }

    & .berkeley {
      width: ${85 * modifier}px;
    }

    & .bytedance {
      width: ${110 * modifier}px;
    }

    & .dsta {
      width: ${80 * modifier}px;
    }

    & .ibm {
      width: ${70 * modifier}px;
    }

    & .grab {
      width: ${80 * modifier}px;
    }

    & .mckinsey {
      width: ${100 * modifier}px;
    }

    & .mit {
      width: ${110 * modifier}px;
    }

    & .sea {
      width: ${60 * modifier}px};
    }

    & .standardchartered {
      width: ${100 * modifier}px;
    }

    & .stanford {
      width: ${90 * modifier}px;
    }

    & .nus {
      width: ${90 * modifier}px;
    }

    & .razer {
      width: ${100 * modifier}px;
    }

    & .smu {
      width: ${120 * modifier}px;
    }

    & .stengineering {
      width: ${120 * modifier}px;
    }
    `;
  }}

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    grid-template-columns: repeat(5, 1fr);
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 0px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      /* &:nth-child(8) {
        grid-column: initial;
      }
      &:nth-child(10) {
        grid-column: 2 / 3;
      } */
    }

    ${() => {
      const modifier = 0.8;
      return `
      & .astar {
        width: ${80 * modifier}px;
      }

      & .barclays {
        width: ${100 * modifier}px};
      }

      & .ibm {
        width: ${50 * modifier}px;
      }

      & .grab {
        width: ${50 * modifier}px;
      }

      & .stanford {
        width: ${70 * modifier}px};
      }

      & .berkeley {
        width: ${70 * modifier}px}};
      }

      & .mit {
        width: ${85 * modifier}px};
      }

      & .bytedance {
        width: ${100 * modifier}px};
      }

      & .standardchartered {
        width: ${70 * modifier}px};
      }

      & .sea {
        width: ${40 * modifier}px};
      }

      & .nus {
        width: ${70 * modifier}px;
      }

      & .razer {
        width: ${80 * modifier}px;
      }

      & .smu {
        width: ${110 * modifier}px;
      }

      & .stengineering {
        width: ${120 * modifier}px;
      }
      `;
    }}
  }
  // #endregion
`;

const InstructorsFrom = () => (
  <Section>
    <Container>
      <Title>Our instructors are from</Title>
      <Logos>
        <div>
          <StaticImage
            src="../../assets/images/landing/barclays.png"
            className="barclays"
            alt="Barclays logo"
          />
        </div>
        <div>
          <BerkeleyLogo className="berkeley" />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/grab.png"
            className="grab"
            alt="Grab logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/IBM.png"
            className="ibm"
            alt="IBM logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/mit.png"
            className="mit"
            alt="MIT Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/nus.png"
            className="nus"
            alt="NUS logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/razer.png"
            className="razer"
            alt="Razer logo"
          />
        </div>
        <div>
          <SeaLogo className="sea" />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/smu.png"
            className="smu"
            alt="SMU logo"
          />
        </div>
        <div>
          <StandardCharteredLogo className="standardchartered" />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/stanford.png"
            className="stanford"
            alt="Stanford logo"
          />
        </div>
        <div>
          <STEngineeringLogo className="stengineering" />
        </div>
      </Logos>
    </Container>
  </Section>
);

export default InstructorsFrom;
