/* eslint-disable */
const axios = require('axios');
const partnersPageVideos = require('./src/components/whyus/partners/items.json');
const onlineClassesVideo = require('./src/components/onlineClasses/item.json');
const imdaGrad2021Videos = require('./src/components/DatascienceBootcampAccelerator2021/Graduation/items.json');

require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
});

/**
 * Retrieves video thumbnail url for video sitemap. Only supports Vimeo and Youtube for now.
 * @param {string} url
 * @returns Promise<string>
 */
const getVideoThumbnail = (url) => {
  const videoPlatformType = (url) => {
    if (url.indexOf('youtube.com') > -1) {
      return { youtube: true };
    }

    if (url.indexOf('vimeo.com') > -1) {
      return { vimeo: true };
    }

    return {};
  };

  const getVideoId = (url) => {
    if (videoPlatformType(url).youtube) {
      return url.match(/embed\/.{11}/)[0].replace('embed/', '');
    }

    if (videoPlatformType(url).vimeo) {
      return url.match(/\d{9}/);
    }

    return '';
  };

  return new Promise((resolve) => {
    if (videoPlatformType(url).youtube) {
      resolve('https://img.youtube.com/vi/' + getVideoId(url) + '/0.jpg');
      return;
    }

    if (videoPlatformType(url).vimeo) {
      axios
        .get(
          `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${getVideoId(
            url
          )}`
        )
        .then((res) => {
          resolve(res.data.thumbnail_url);
        })
        .catch(() => {
          resolve('');
        });
      return;
    }

    resolve('');
  });
};

module.exports = {
  siteMetadata: {
    title: 'Top Coding School in Malaysia | Programming Courses Malaysia',
    description:
      'Skill up and earn globally recognised tech certifications with our highly qualified instructors.',
    author: 'MY Code Campus',
    siteUrl: 'https://www.mycodecampus.com',
    env: process.env.NODE_ENV,
  },
  trailingSlash: 'ignore',
  plugins: [
    {
      resolve: 'gatsby-plugin-react-helmet',
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'markdowns',
        path: `${__dirname}/src/markdowns`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'courses',
        path: `${__dirname}/src/components/courses/courses.json`,
      },
    },
    'gatsby-transformer-json',
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        // Footnotes mode (default: true)
        footnotes: false,
        // GitHub Flavored Markdown mode (default: true)
        gfm: true,
        plugins: [
          {
            resolve: `gatsby-remark-images`,
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 970,
              quality: 100,
              linkImagesToOriginal: false,
              withWebp: true,
            },
          },
          {
            resolve: 'gatsby-remark-prismjs',
            options: {
              classPrefix: 'language-',
              inlineCodeMarker: null,
              showLineNumbers: false,
              noInlineHighlight: false,
            },
          },
          { resolve: 'gatsby-remark-copy-linked-files' },
        ],
      },
    },
    {
      resolve: 'gatsby-remark-prismjs',
      options: {
        classPrefix: 'language-',
        inlineCodeMarker: null,
        showLineNumbers: false,
        noInlineHighlight: false,
      },
    },
    {
      resolve: 'gatsby-source-ghost',
      options: {
        apiUrl: process.env.GHOST_API_URL,
        contentApiKey: process.env.GHOST_CONTENT_API_KEY,
        version: 'v3',
      },
    },
    {
      resolve: 'gatsby-plugin-image',
    },
    {
      resolve: 'gatsby-transformer-sharp',
    },
    {
      resolve: 'gatsby-plugin-sharp',
      options: {
        defaults: {
          formats: ['auto', 'webp'],
          placeholder: 'blurred',
          quality: 100,
          breakpoints: [750, 1080, 1366, 1920],
          backgroundColor: 'transparent',
        },
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'SG Code Campus',
        short_name: 'SGCC',
        start_url: '/',
        background_color: '#1B75BC',
        theme_color: '#F37021',
        display: 'standalone',
        icon: 'src/assets/images/sgcc-logo.png', // This path is relative to the root of the site.
      },
    },
    {
      resolve: 'gatsby-plugin-google-gtag',
      options: {
        trackingIds: ['UA-74793127-1'],
        pluginConfig: {
          head: true,
        },
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rules: [
          {
            test: /\.svg$/,
            include: /assets\/.*/,
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-styled-components',
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        output: '/',
        // Queries GraphQL for the paths.
        query: `
          {
            allSitePage {
              edges {
                node {
                  pageContext
                  path
                  component
                }
              }
            }
          }
        `,
        resolveSiteUrl: () => 'https://sgcodecampus.com',
        // Resolves GraphQL query for the next step, serialize().
        resolvePages: ({ allSitePage }) => {
          return allSitePage.edges.map(
            ({ node: { path, pageContext, component } }) => {
              return { path, pageContext, component };
            }
          );
        },
        // Returns sitemap structure.
        serialize: async ({ pageContext, path, component }) => {
          const { html, title, project } = pageContext;
          const param = {
            url: path,
            changefreq: 'daily',
            priority: 0.7,
          };
          const getLink = ({ link, title, description }) => {
            return getVideoThumbnail(link).then((thumbnail_loc) => {
              return {
                content_loc: link,
                title,
                description,
                thumbnail_loc,
              };
            });
          };
          const getLinks = (links) => {
            const promises = [];

            links.forEach((link) => {
              promises.push(getLink({ ...link }));
            });

            return Promise.all(promises).then((parsedLinks) => ({
              ...param,
              video: parsedLinks,
            }));
          };
          const getIframeSrcs = (str) => {
            const iframes = str.match(/<iframe(.|\s|\n)+?iframe>/g) || [];
            const promises = [];

            iframes.forEach((iframe) => {
              const videoUrl = iframe
                .match(/src="(.|\s|\n)+?"/)[0]
                .replace('src=', '')
                .replace(/"/g, '');

              promises.push(
                getLink({ link: videoUrl, title, description: title })
              );
            });

            return Promise.all(promises).then((parsedIframes) => ({
              ...param,
              video: parsedIframes,
            }));
          };

          // Only looks at blog pages and buildon workshop pages for now.
          // To be added: Self hosted videos.
          if (
            component.indexOf('IndividualBlogPage.jsx') > -1 ||
            component.indexOf('IndividualMDPage.jsx') > -1
          ) {
            return getIframeSrcs(html);
          }

          // Looks at Basics X pages
          if (component.indexOf('IndividualXPage.jsx') > -1 && project) {
            return getLink({
              link: project.link,
              title: project.title,
              description: project.title,
            }).then((obj) => ({
              ...param,
              video: [obj],
            }));
          }

          // Looks at these individual React pages to pull video links
          // JSON file listing all videos required for these pages
          if (
            ['/online-classes/', '/imda-grad-2021/', '/partners/'].indexOf(
              path
            ) > -1
          ) {
            let jsonObject;

            switch (path) {
              case '/online-classes/':
                jsonObject = [onlineClassesVideo].map((video) => ({
                  link: video.video,
                  title: video.title,
                  description: video.title,
                }));
                break;
              case '/partners/':
                jsonObject = partnersPageVideos
                  .filter((partner) => partner.video)
                  .map((partner) => ({
                    link: partner.video,
                    title: partner.className,
                    description: `SGCC partnership with ${partner.className}`,
                  }));
                break;
              case '/imda-grad-2021/':
                jsonObject = imdaGrad2021Videos.map((video) => ({
                  link: video.src,
                  title: video.title,
                  description: `IMDA 2021 ${video.title}`,
                }));
                break;
              default:
                break;
            }

            return getLinks(jsonObject);
          }

          return new Promise((resolve) => {
            resolve(param);
          });
        },
      },
    },
    {
      resolve: 'gatsby-plugin-canonical-urls',
      options: {
        siteUrl: 'https://www.sgcodecampus.com',
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.sgcodecampus.com',
        sitemap: 'https://www.sgcodecampus.com/sitemap-index.xml',
        policy: [
          {
            userAgent: '*',
            allow: '/',
            disallow: [
              '/learnmore/faq',
              '/schedule/?',
              '/schools',
              '/youth-programmes/',
              '/schedule/',
              'youth-programmes/basics/',
              '/youth-programmes/principles/',
              '/student-achievements/',
              '/policies/',
              '/summer-scratch-competition-2021',
              '/build-on/',
            ],
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-facebook-pixel',
      options: {
        pixelId: '1654575968194699',
      },
    },
    {
      resolve: 'gatsby-plugin-intercom',
      options: {
        appId: 'qys8lrd9',
      },
    },
    {
      resolve: 'gatsby-plugin-hotjar',
      options: {
        id: 1326223,
        sv: 6,
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-WP84Q5J',
        includeInDevelopment: false,
      },
    },
    {
      resolve: 'gatsby-plugin-catch-links',
    },
    {
      resolve: 'gatsby-plugin-hubspot',
      options: {
        trackingCode: '45871152',
        respectDNT: false,
        productionOnly: true,
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.app/offline
    // 'gatsby-plugin-offline',
  ],
};
