import styled from 'styled-components';
import shortid from 'shortid';

import $ from '../../styles/global';
import FBIcon from '../../assets/icons/fb.svg';
import IGIcon from '../../assets/icons/ig.svg';
import LinkedInIcon from '../../assets/icons/linkedin.svg';
import items from '../Navbar/items.json';
import { Subtitle } from './FooterStyles';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: footerSocial;
    width: 50%;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    ${Subtitle} {
      margin-bottom: 12px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 4;
  }
  // #endregion
`;

const SocialIconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & svg {
    stroke-width: 0px;
    fill: ${$.color.white};
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${$.layout().padding3}px;
    & svg {
      width: 18px;
      height: 18px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${$.layout().padding3}px;
    & svg {
      height: 17px;
    }
  }
  // #endregion
`;

const footerItems = {};

items.forEach(({ footerItem, dropdownLink, dropdownText, subItems = [] }) => {
  footerItems[footerItem] = footerItems[footerItem] || {
    link: dropdownLink || null,
    text: dropdownText,
    subItems: [],
  };

  subItems.forEach(({ subText, subLink }) => {
    footerItems[footerItem].subItems.push({
      key: shortid.generate(),
      link: subLink,
      text: subText,
    });
  });
});

const SocialIcons = () => (
  <Container>
    <Subtitle>Follow Us</Subtitle>
    <SocialIconsContainer>
      {/* <a
        href="https://sg.linkedin.com/company/sg-code-campus"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon />
      </a> */}
      <a
        href="https://www.facebook.com/share/kotmaD86h7j5H2L5/?mibextid=LQQJ4d"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FBIcon />
      </a>
      <a
        href="https://www.instagram.com/mycodecampus/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IGIcon />
      </a>
    </SocialIconsContainer>
  </Container>
);

export default SocialIcons;
