import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import RightChevron from '../../assets/icons/thin-arrow-right.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: Lato Regular;
  line-height: 18px;
  position: relative;

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.white};
    margin-left: 8px;
    transition: all 0.5s;
    right: 0;
    position: relative;
  }
`;

const Button = styled.div`
  background: radial-gradient(
    50% 628.51% at 50% 50%,
    ${$.proTraining.blue} 0%,
    #114060 100%
  );
  width: calc(100% - 60px - 2px);
  color: ${$.color.white};
  border-radius: 54px;
  border: 1px solid transparent;
  padding: 12px 30px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  letter-spacing: 0;
  transition: left 0.3s, transform 0.3s, color 0.5s, letter-spacing 0.3s;

  & > span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: ${$.color.white};
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 20%);
    transition: 0.5s;
    top: 0;
    transition-delay: calc((var(--n) - 1) * 0.05s);
    z-index: -1;

    &:nth-child(2) {
      --n: 1;
    }

    &:nth-child(3) {
      --n: 2;
    }

    &:nth-child(4) {
      --n: 3;
    }

    &:nth-child(5) {
      --n: 4;
    }

    &:nth-child(6) {
      --n: 5;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    padding: 9px 27px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
  }
  // #endregion
`;

const CardButton = ({ text, className }) => (
  <Button className={className}>
    <Container>
      {text}
      <RightChevron />
    </Container>
    <span />
    <span />
    <span />
    <span />
    <span />
  </Button>
);

CardButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
CardButton.defaultProps = {
  text: 'Learn More',
  className: '',
};

export default CardButton;
