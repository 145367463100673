import { StaticImage } from 'gatsby-plugin-image';

const SMUAcademyImage = (
  <StaticImage
    src="../../assets/images/professional-training/smuacademy.png"
    alt="SMU Academy image"
  />
);

const AWSTrainingAndCertificationImage = (
  <StaticImage
    src="../../assets/images/professional-training/aws-training-and-certification.png"
    alt="AWS Training and Certification image"
  />
);

const AppleAppDevelopmentWithSwiftImage = (
  <StaticImage
    src="../../assets/images/professional-training/app-development-with-swift.png"
    alt="AWS Training and Certification image"
  />
);

const GoogleCloudImage = (
  <StaticImage
    src="../../assets/images/professional-training/google-cloud.png"
    alt="Google Cloud image"
  />
);

const buttonMessages = {
  smu: 'Visit Course Page',
  internal: 'Enquire Now',
};

const Items = [
  {
    value: 'aws',
    tab: 'AWS',
    courses: [
      // {
      //   title:
      //     'Career Transition Programme: Software Development with the Amazon Web Services (AWS) Cloud',
      //   desc: 'Mid-career transition programme to train software developers proficient with the AWS Cloud. Taught at the Singapore Management University by a team of AWS Authorised Instructors and industry professionals.',
      //   image: SMUAcademyImage,
      //   level: 'Basic',
      //   venue: 'Singapore Management University',
      //   buttonMessage: buttonMessages.smu,
      //   link: '/courses/smu/sctp-advanced-certificate-software-development-amazon-web-services-aws-cloud/',
      //   fee: '$19,620* (as low as $1,386 using SkillsFuture Credits)',
      // },
      // {
      //   title: 'Advanced Certificate in AWS Cloud Architecting and DevOps',
      //   desc: "Want to gain the skills required to capitalise on global opportunities in the brisk job market for AWS Cloud talent? Look no further as this programme will teach you how to design and utilise functionality on Amazon Web Services, the world's largest provider of Cloud Services (AWS).",
      //   image: SMUAcademyImage,
      //   level: 'Basic',
      //   venue: 'Singapore Management University',
      //   buttonMessage: buttonMessages.smu,
      //   link: '/courses/smu/advanced-certificate-aws-cloud-architecting-and-devops/',
      //   fee: '$10,464* (as low as $1,219.20 using SkillsFuture Credits)',
      // },
      {
        title: 'AWS Cloud Practitioner Essentials',
        desc: 'The AWS Cloud Practitioner Essentials course is an introductory level course designed to provide participants with a foundational understanding of the AWS Cloud. The course covers the core AWS services, including computing, storage, databases, and networking.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/aws-cloud-practitioner-essentials/',
        fee: '$1,090 (GST included)',
      },
      {
        title: 'Architecting on AWS',
        desc: 'Students learn how to optimize the AWS Cloud by understanding how AWS services fit into cloud-based solutions. In addition, students explore AWS Cloud best practices and design patterns for architecting optimal IT solutions on AWS, and build a variety of infrastructures in guided, hands-on activities.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/architecting-on-aws/',
        fee: '$2,779.50 (GST included)',
      },
      {
        title: 'Cloud Operations on AWS',
        desc: 'This course teaches systems operators, and anyone performing cloud operations functions how to manage and operate automatable and repeatable deployments of networks and systems on AWS.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/cloud-operations-on-aws/',
        fee: '$2,779.50 (GST included)',
      },
      {
        title: 'Developing on AWS',
        desc: 'This course teaches experienced developers how to programmatically interact with AWS services to build web solutions. It guides you through a high-level architectural discussion on resource selection and dives deep into using the AWS Software Development Kits (AWS SDKs) and Command Line Interface (AWS CLI) to build and deploy your cloud applications.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/developing-on-aws/',
        fee: '$2,779.50 (GST included)',
      },

      // {
      //   title:
      //     'Certificate in Technology Foundations: Establishing Foundation in Cloud Services and Applications',
      //   desc: "This programme introduces critical technology through the world's largest provider of Cloud Computing - Amazon Web Services (AWS).",
      //   image: SMUAcademyImage,
      //   level: 'Basic',
      //   venue: 'Singapore Management University',
      //   buttonMessage: buttonMessages.smu,
      //   link: '/courses/smu/certificate-technology-foundations-establishing-foundation-cloud-services-and-applications/',
      //   fee: '$1,744* (as low as <b>$203.20</b> using SkillsFuture Credits)',
      // },
      // {
      //   title: 'Advanced Certificate in AWS Cloud Services and Technicals',
      //   desc: 'The demand for Cloud Computing continues to rise exponentially amid the digital transformation of organisations. Gaining expertise in Amazon Web Services (AWS), the leading provider of Cloud Computing services, has become a necessity for IT professionals.',
      //   image: SMUAcademyImage,
      //   level: 'Basic',
      //   venue: 'Singapore Management University',
      //   buttonMessage: buttonMessages.smu,
      //   link: 'mailto:enquiries@sgcodecampus.com?subject=Advanced Certificate in AWS Cloud Services and Technicals',
      //   fee: 'Refer to course page',
      // },
    ],
  },
];

export default Items;
