import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';
import PromoDetails from './PromoDetails';

const Section = styled.section`
  padding: ${$.layout().padding4}px 0px;
  margin-bottom: 0px !important;
  background-color: ${$.color.orange3};
  color: ${$.color.white};
  font-family: Aileron Regular;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const PromoBanner = ({ className }) => (
  <Section className={className}>
    <Container>
      <PromoDetails />
    </Container>
  </Section>
);

PromoBanner.defaultProps = {
  className: '',
};

PromoBanner.propTypes = {
  className: PropTypes.string,
};

export default PromoBanner;
