import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import $ from '../../styles/global';
import { Title, Subtitle } from './FooterStyles';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: footerDirectory;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    grid-area: footerDirectory;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 3;
  }
  // #endregion
`;

const DirectoryTitle = styled(Title)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    flex: 0 1 auto;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    flex: 0 1 auto;
  }
  // #endregion
`;

const LinksContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  // #endregion
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 13px;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const SmallerSubtitle = styled(StyledSubtitle)`
  font-size: 13px;
`;

const Column = styled.div`
  a {
    text-decoration: none;
    color: ${$.color.blue2};
  }

  ${StyledSubtitle} > a, ${SmallerSubtitle} > a {
    color: white;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(({ link, className, children }) => {
  if (link.indexOf('http') > -1) {
    return (
      <a
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
})`
  line-height: 1.4em;

  br {
    content: '';
    display: block;
  }
`;

const Item = styled.div`
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > ${StyledLink} {
      display: none;
    }
  }
  // #endregion
`;

const setSubLinks = ({ name, footerItems }) =>
  footerItems[name].subItems.map(({ key, link, text }) => (
    <StyledLink key={key} link={link}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </StyledLink>
  ));

const Directory = ({ footerItems }) => (
  <Container>
    <DirectoryTitle>Directory</DirectoryTitle>
    <LinksContainer>
      <Column>
        <Item>
          <StyledSubtitle>
            <Link to="/">Home</Link>
          </StyledSubtitle>
        </Item>
        <Item>
          <StyledSubtitle>
            <Link to={footerItems.about.subItems[0].link}>
              {footerItems.about.text}
            </Link>
          </StyledSubtitle>
          {setSubLinks({ name: 'about', footerItems })}
        </Item>
        {/* <Item>
          <SmallerSubtitle>
            <Link
              to={footerItems.enrichment.subItems[0].link}
              dangerouslySetInnerHTML={{
                __html: footerItems.enrichment.text.replace(' ', '<br />'),
              }}
            />
          </SmallerSubtitle>
          {setSubLinks({ name: 'enrichment', footerItems })}
        </Item> */}
      </Column>
      <Column>
        <Item>
          <SmallerSubtitle>
            <Link
              to={footerItems.professional.subItems[0].link}
              dangerouslySetInnerHTML={{
                __html: footerItems.professional.text.replace(' ', '<br />'),
              }}
            />
          </SmallerSubtitle>
          {setSubLinks({ name: 'professional', footerItems })}
        </Item>
      </Column>
    </LinksContainer>
  </Container>
);

Directory.propTypes = {
  footerItems: PropTypes.shape({
    about: PropTypes.oneOfType([PropTypes.object]),
    professional: PropTypes.oneOfType([PropTypes.object]),
    enrichment: PropTypes.oneOfType([PropTypes.object]),
    tertiary: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default Directory;
