import PropTypes from 'prop-types';

import WayOfThinking from '../components/whyus/philosophy/WayOfThinking';
import Proficiencies from '../components/whyus/philosophy/Proficiencies';
import ConceptualUnderstanding from '../components/whyus/philosophy/ConceptualUnderstanding';
import ProgrammeCards from '../components/whyus/philosophy/ProgrammeCardsContainer';
import SolvingProblems from '../components/whyus/philosophy/SolvingProblems';
import BuildBetterFuture from '../components/whyus/philosophy/BuildBetterFuture';
import Banner from '../components/MiniBanner';
import SEO from '../components/seo';

const Philosophy = ({ location }) => (
  <>
    <SEO location={location} keywords={['best coding curriculum']} />
    <Banner
      title="Our Curriculum Philosophy"
      subtitle="Defining a best-in-class computer science curriculum for professionals and adults"
    />
    <WayOfThinking />
    <Proficiencies />
    <ConceptualUnderstanding />
    <ProgrammeCards />
    <SolvingProblems />
    <BuildBetterFuture />
  </>
);

Philosophy.defaultProps = {
  location: {},
};

Philosophy.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Philosophy;
