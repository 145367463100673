import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import info from '../../campusInfo.json';
import PhoneIcon from '../../../assets/icons/phone.svg';
import $ from '../../../styles/global';
import Util from '../../../utils';
import Button from '../../Button';

const Section = styled.section`
  background-color: ${$.color.blue1};
  padding: ${$.layout().padding2}px 0;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding1}px 0;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
  // #endregion
`;

const ContactInfo = styled.div`
  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const ContactInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  fill: ${$.color.orange3};
  width: 20px;
  margin-right: ${$.layout().margin5}px;
`;

const PhoneInfoContent = styled.div`
  display: inline-block;
  font-size: 18px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: block;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const PhoneInfo = styled.div`
  ${PhoneInfoContent} {
    &:first-child {
      margin-right: ${$.layout().margin3}px;
    }
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    ${PhoneInfoContent} {
      &:first-child {
        margin-right: ${$.layout().margin4}px;
      }
    }
  }
  // #endregion
`;

const PhoneInfoTitle = styled.div`
  font-family: Lato Bold;
  font-size: 20px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const PhoneInfoContentTitle = styled.div`
  font-family: Lato Bold;
  font-size: 18px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin4}px;
`;

const PhoneInfoItem = styled.div`
  color: ${$.color.blue4};
  font-size: 18px;
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
  }
  // #endregion
`;

const CallTo = styled.a`
  text-decoration: none;
  border-bottom: 2px solid ${$.color.blue4};
  font-family: Lato Bold;
  color: ${$.color.blue4};
  font-size: 19px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 18px;
  }
  // #endregion
`;

const StyledButton = styled(Button)`
  font-size: 17px;
`;

const offpeak = Util.addKeys(info.offpeak.map((item) => ({ value: item })));

const peak = Util.addKeys(info.peak.map((item) => ({ value: item })));

const OperatingHours = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        <ContactInfo>
          <ContactInfoContent>
            <StyledButton
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(
                    'mailto:enquiries@mycodecampus.com?subject=Enquiry%20from%20Website'
                  );
                }
              }}
            >
              Enquire Now
            </StyledButton>
          </ContactInfoContent>
          <ContactInfoContent>
            <StyledPhoneIcon />
            <CallTo href={`tel:${info.phone.replace(/-| /, '')}`}>
              {info.phone}
            </CallTo>
          </ContactInfoContent>
        </ContactInfo>
        <PhoneInfo>
          <PhoneInfoTitle>Phone Line Hours</PhoneInfoTitle>
          <div>
            <PhoneInfoContent>
              <PhoneInfoContentTitle>Off-Peak Period</PhoneInfoContentTitle>
              {offpeak.map(({ value, key }) => (
                <PhoneInfoItem key={key}>{value}</PhoneInfoItem>
              ))}
            </PhoneInfoContent>
            <PhoneInfoContent>
              <PhoneInfoContentTitle>Peak Period</PhoneInfoContentTitle>
              {peak.map(({ value, key }) => (
                <PhoneInfoItem key={key}>{value}</PhoneInfoItem>
              ))}
            </PhoneInfoContent>
          </div>
        </PhoneInfo>
      </Container>
    </Fade>
  </Section>
);

export default OperatingHours;
